import React from "react";
import careerimg from '../../images/careerimg.jpg';
import NavBar from "../NavBar";
import Oladapo from '../../images/oladapo.png';
import Footer from "./Footer";

import "./Afolabi.css";
export const Afolabi = () => {
  return (
    <div>
      <div className="Afolabicontainer">
  
  <div>
     
    <h1 className="centered-text">Deo Alpha Health Services Limited</h1>
    <p className="centered-text" style={{ fontSize: "20px" }}>World Class Health Company</p>
  </div>
    
  </div>
  <body>
  <div className="teambody">
    <p class="image-and-text">
        <img src={Oladapo} alt="" className="teamimage" />
        <span>
        Professor Afolabi is a specialist in Environmental and Food Nutritional Chemistry. He is an alumnus of Obafemi Awolowo University Ile Ife, Osun State, where he obtained his B.Sc. in 1975, Afolabi also got his MSc. also in Biochemistry from the same institution before bagging his Ph.D. in Applied Chemistry in 1981. He has taught in many institutions in Nigeria and outside the country including the Obafemi Awolowo University, Ladoke Akintola University of Technology, Ogbomosho, and the University of Zimbabwe
        </span>
    <p>
      <span>
      For the former Head of the Civil Service of the Federation, the civil service years started in 1991 when he joined the Federal Environmental Protection Agency (FEPA) from where he rose to the rank of an Acting Director. From FEPA, he joined the Ministry of Environment in 1995. In October 2006, he was appointed a permanent secretary and in June 2007 became the Permanent Secretary in charge of the Labour ministry.
      </span>
    </p>
      <p>
        <span>
        In November 2007, he was posted to the Ministry of Agriculture and Water Resources to lay a solid groundwork for the newly merged ministry. In 2009 he was redeployed to the Cabinet Secretariat from where in August of same year he was appointed the Permanent Secretary, Ministry of Education. From here he was appointed the Head of the Civil Service of the Federation and retired in September,2011.
        </span>
        </p> 
        <p>
          <span>
          Professor Afolabi is a Fellow of the International Atomic Energy Agency; Institute of Chartered Chemists of Nigeria; Chartered Institute of Local Government and Public Administrators of Nigeria, Institute of Public Analysts of Nigeria, Michael Imoudu Institute for Labour Studies. He was conferred the National honors of OON and CFR in 2009 and 2011 respectively.
          </span>
          </p>    
    </p>
</div>


    <Footer />
  </body>
    </div>
  );
};


