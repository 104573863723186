import React from "react";
import careerimg from '../../images/careerimg.jpg';
import NavBar from "../NavBar";
import Footer from "./Footer";

import "./Careers.css";
export const Careers = () => {
  return (
    <div>
       <div className="careercontainer">
  
    <div className="main">
       
      <h1 className="centered-text">Careers</h1>
    </div>
    
  </div>
  <body>
    <div className="message">
      <div className="partner">
        <h3>
        Want to work or partner with us?
        </h3>
      </div>
      <div className="mail">
        <p>
        Message us
        </p>
        <h3>
        info@dalphahealth.com
        </h3>
      </div>
    </div>
    <div className="peopleimg" >
    
    </div>
    <Footer />
  </body>
    </div>
  );
};


