import React from "react";
import careerimg from '../../images/careerimg.jpg';
import NavBar from "../NavBar";
import segun from '../../images/segun.png';
import Footer from "./Footer";

import "./Afolabi.css";
export const Segun = () => {
  return (
    <div>
         <div className="Afolabicontainer">
  
  <div>
     
    <h1 className="centered-text">Deo Alpha Health Services Limited</h1>
    <p className="centered-text" style={{ fontSize: "20px" }}>World Class Health Company</p>
  </div>
    
  </div>
  <body>
  <div className="teambody">
    <p class="image-and-text">
        <img src={segun} alt="" className="teamimage" />
        <span>
        
He is an alumnus of the Medical School of the Ahmadu Bello University Zaria, where he obtained his Bachelor’s degree in medicine (M.B.B.S) in 1981. He also completed a Postgraduate Residency Program in Pediatrics at the Obafemi Awolowo University in 1987 to become a Fellow of the West African College of Physicians (FWACP).
        </span>
    <p>
      <span>
      He has held various positions of increasing responsibilities including being a Lecturer and a Consultant Pediatrician at Obafemi Awolowo College of Medical Sciences, Ogun State University (Olabisi Onabanjo University). He was also a Consultant pediatrician and Medical officer in charge, Medical Center, Osogbo Steel Rolling Company, a company of the Federal Ministry of Mines and Power.
      </span>
    </p>
      <p>
        <span>
        He is the immediate past Medical and Regulatory Affairs Director for Pfizer Specialties Limited, a wholly subsidiary of Pfizer Inc. New York, a global Pharmaceutical Company. He held this position for 12 years and had responsibility for Pfizer Medical operations in East, Central and Anglophone West Africa. While in Pfizer, he had numerous in-house trainings in various aspects of the Pharmaceutical industry.
        </span>
        </p> 
        <p>
          <span>
          His functions in Pfizer and expertise include the following: Medical-Marketing affairs, Field Force training, Regulatory affairs, Product registration, Clinical trials, Pharmacovigilance, Medical Information, etc.
          </span>
        </p>
        <p>
          <span>
          He is currently a Consultant Pediatrician with the Lagos State University Teaching Hospital, Ikeja.
          </span>
          </p>    
          
    </p>
</div>


    <Footer />
  </body>
    </div>
  );
};


