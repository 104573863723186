import React from "react";
import careerimg from '../../images/careerimg.jpg';
import NavBar from "../NavBar";
import lere from '../../images/lere.png';
import Footer from "./Footer";

import "./Afolabi.css";
export const Lere = () => {
  return (
    <div>
         <div className="Afolabicontainer">
  
  <div>
     
    <h1 className="centered-text">Deo Alpha Health Services Limited</h1>
    <p className="centered-text" style={{ fontSize: "20px" }}>World Class Health Company</p>
  </div>
    
  </div>
  <body>
  <div className="teambody">
    <p class="image-and-text">
        <img src={lere} alt="" className="teamimage" />
        <span>
        An inspiring, visionary & resilient Leader with passion for entrepreneurship is leveraging on his rich strategic skills, enterprise architecting and business transformation experience spanning FMCG, Telecoms and Higher Education industries to create and translate visions into reality in order to manage businesses that seek to take advantage of opportunities in the economies of Africa & Middle East.
        </span>
    <p>
      <span>
      Currently provides leadership as CEO of Business School Netherlands, BSN-Nigeria. Also serves as Regional Director of Centre for Management & Organizational Effectiveness – CMOE Inc (USA) for countries in Middle East & Africa Region using Howes Consulting Group as a special purpose vehicle for the promotion of the global partners’ integrated consulting solutions in the region. Served the pioneering Governing Management Board of Lagos State University Teaching Hospital and a Facilitator / Mentor for Fate Foundation. 
      </span>
    </p>
      <p>
        <span>
        Currently provides leadership as CEO of Business School Netherlands, BSN-Nigeria. Also serves as Regional Director of Centre for Management & Organizational Effectiveness – CMOE Inc (USA) for countries in Middle East & Africa Region using Howes Consulting Group as a special purpose vehicle for the promotion of the global partners’ integrated consulting solutions in the region. Served the pioneering Governing Management Board of Lagos State University Teaching Hospital and a Facilitator / Mentor for Fate Foundation. 
        </span>
        </p> 
        <p>
          <span>
          Has over three decades of business management experience in leading world class global firms including – Pfizer Worldwide Biopharmaceutical Businesses, BSN & CMOE. At Pfizer, he served as Regional Director and resiliently led project teams, initiatives, development and implementation of business strategies for rebuilding Pfizer – the biggest global pharmaceutical firm afresh starting from ground zero in Nigeria in 1997 to cover over 32 countries by 2004. 
          </span>
        </p>
        <p>
          <span>
          He is the first certified Action Learning Coach in ECAWA Region of Africa, an Associate Professor at Business School, Netherlands, Fellow of Nigerian Institute of Management & Facilitator of NIM Certification Programme at Command Staff College, Jaji, Nigeria, a Fellow of National Institute of Marketing, Nigeria, a Distinguished Fellow of the Professional Excellence Foundation of Nigeria, a Fellow of Institute of Management Consultants, a Fellow of Business Process Management Institute, a Fellow of Marketing Foundation, and a member of Institute of Directors. Has also been given the highest Fellowship, honours and awards by different pharmaceutical professional bodies including West African Post Graduate College of Pharmacy, Nigeria Academy of Pharmacy, 
          </span>
          </p>    
          <p>
            <span>
            Pharmaceutical Society of Nigeria etc. He is on the board of Nigeria – Alumni Association of Haggai Leadership International USA, a Charter President of BSN Toastmaster & a member of Ikoyi Club 1938 with passion for Golf.
            </span>
          </p>
    </p>
</div>


    <Footer />
  </body>
    </div>
  );
};


