import React from "react";
import { NavLink } from "react-router-dom";
import NavBar from "../NavBar";
import research from '../../images/research.jpg';
import commercialization from '../../images/commercialization.jpg';
import technologies from '../../images/technologies.jpg';
import therapeutic from '../../images/therapeutic.jpg';
import femaleico from '../../images/femaleico.png';
import share from '../../images/shareicon.png';
import mark from '../../images/mark.png';
import hands from '../../images/shakehands.png';
import Footer from "./Footer";
import "./Services.css";
export const Services = () => {
  return (
    <div>
      <div className="servicecontainer">
  

    <div className="main">
      
      <h1 className="centered-text">Our Services</h1>
    </div>
    
  </div>
  <body>
    <div>
      <h2 className="service-centered-text">
      We cover a large range of solutions
      </h2>
      <div className="ranges">
        <div>
          <div>
          <img src={research} alt="" className="rangesimg" />
          </div>
          <div className="rangestext">
          <h2>
          Research and Development
          </h2>
          <p>
          Deo Alpha Health Service understands the importance of data science and human science in research and development and will combine both for a robust outcome. We will help you perfect your study design choosing the best parameters like sample size etc to ensure accurate results for your molecule and prevent study failure. We pay attention to site selection, patient recruitment and retention and other factors to obtain precise outcomes while ensuring patient safety.

Through our R & D management consulting, we compliment the R & D of your company so you can focus energies and resources on other areas. When you are comfortable enough, we can assist in setting up a standard R & D department, recruit and train staff to run them and support your outfit with standard results.
          </p>
          </div>
         
        </div>
        <div>
          <div>
          <img src={commercialization} alt="" className="rangesimg" />
          </div>
          <div className="rangestext">
          <h2>
          Commercialization
          </h2>
          <p>
          Deo Alpha Health Service through precision market research and analysis helps you understand your markets ensuring commercialization of your product is seamless. Our renowned business consultants will help you build your brand making it recognizable, reputable and competitive through world class brand management and promotion strategies.

For new products, a good launch is key. We help develop exciting and innovative launches to propel products into the market and aid acceptance. Our expertise in developing field teams, medical reps, managers, supervisors etc comes in handy in establishing this very critical part of the production process and helps expand the market share of the product progressively.

After sales research, analysis and management coordinate objective and subjective feedbacks on the brand which provide fodder for tweaking various points in your enterprise. We are fully committed to ensuring that all aspects of commercialization and marketing are covered.    
    </p>
          </div>
          
       
        </div>
        <div>
          <div>
          <img src={technologies} alt="" className="rangesimg" />
          </div>
          <div className="rangestext">
          <h2>
          Technologies
          </h2>
          <p>
          Advancements in technology continue to change the way diseases are diagnosed, monitored, treated and managed.

In Deo Alpha Health Service, our technical unit is fully equipped to assist in making real, brilliant ideas of medical devices, drug delivery systems and other technologies for the advancement of innovation in medical science.

The use of these devices is subject to various uncertainties like user issues, device issues etc. We are experts in designing, deploying and analyzing studies aimed at producing a perfect device and developing appropriate user manuals for them.

          </p>
          </div>
          
        </div>
        <div className="rangesdiv">
          <div>
          <img src={therapeutic} alt="" className="rangesimg" />
          </div>
          <div className="rangestext">
          <h2>
          Therapeutic areas
          </h2>
          <p>
          Deo Alpha Health Service understands the pecularities and uniqueness of various disease conditions and develops tailor made research solutions that fit like a glove, especially for neglected diseases prevalent in Africa and third world countries. This ensures that atypical and disease specific variables and observations are not lost in the cloud of generalisation.

Cardiovascular, endocrine, nervous, immune and all other systems, as well as anti-infectives, analgesics, antipsychotics, fertility, oncology, paediatric, gerriatric and other medications have inter and intra class variations that make research into their pharmacokinetic and pharmacodynamic properties different. At D'Alpha, a clear understanding perception of all systems at play in a particular research instance is embarked upon to develop a specific study design which tests the variable at the most possible granular level to yeild results as accurate as they can be.       
          </p>
          </div>
         
        </div>
      </div>
    </div>
    {/* <a href="/contact" >Contact Us</a> */}
 <div className="servicewhy">
   <div className="whybody">
    <div className="whyfirsttext">
      <h1>
      Why go with us?
      </h1>
      <p>
      Are you a drug manufacturer, research and development organization, government body or allied institutions, associations or individual interested in clinical trials, health research of all levels? Consider Deo Alpha Health Service because:
      </p>
    </div>
    <div className="grid">
      <div className="serviceitems">
        <div>
        <img src={femaleico} alt="" className="femaleimg"/>
        </div>
     
      <div className="servicetext">
        <h2>
          
Our expertise
        </h2>
        <p>
        We have experience on the world stage as can be attested to by the composition of our team.
        </p>
      </div>
      </div>
      <div className="serviceitems">
      <img src={share} alt="" className="shareimg" />
      <div>
        <h2>
        Our network
        </h2>
        <p>
        Extensive network with all conceivable institutions relevant to clinical trials, researches and other scientific endeavors.
        </p>
      </div>
      </div>
      <div className="serviceitems">
      <img src={mark} alt=""className="markimg"/>
      <div>
        <h2>
        Our dedication to excellence
        </h2>
        <p>
        We ensure compliance with guidelines and procedures laid down by relevant bodies while applying our dedication to accountability and excellence to deliver products and or results of world class standards.
        </p>
      </div>
      </div>
      <div className="serviceitems">
      <img src={hands} alt="" className="handimg"/>
      <div>
        <h2>
        Our commitment to clients
        </h2>
        <p>
        Extensive network with all conceivable institutions relevant to clinical trials, researches and other scientific endeavors.
        </p>
      </div>
      </div>
    </div>
   </div>
 </div>
    <div className="servicenavigate">
      <h3>
      …navigating from idea to market, presenting the perfect product
      </h3>
      <button className="servicebutton">
     
      <NavLink
                exact
                to="/contact"
              
              >
                Contact Us
              </NavLink>
      </button>
    </div>
    <Footer />
  </body>
    </div>
  );
};
