import React, { useState } from 'react';
import NavBar from "../NavBar";
import Footer from "./Footer";
import "./Contact.css";

export const Contact = () => {
  const [formData, setFormData] = useState({
    FName: '',
    LName: '',
    mail: '',
    phone: '',
    company: '',
    subject: '',
    message: '',
  });
  const requiredFields = ['FName', 'LName', 'mail', 'phone', 'company', 'subject', 'message'];

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const isFormValid = requiredFields.every(fieldName => formData[fieldName].trim() !== '');
    if (!isFormValid) {
      alert('Please fill in all the required fields.');
      return;
    }else{
      fetch('https://dalphahealth.connectlinks.net/mail.php', {
        method: 'POST',
        body: JSON.stringify(formData),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            alert('Message sent successfully!');
            setFormData({
              FName: '',
              LName: '',
              mail: '',
              phone: '',
              company: '',
              subject: '',
              message: '',
            });
          } else {
            alert('Message sending failed. Please try again later.');
          }
        });
    }
    // Send the form data to the PHP script
   
  };

  return (
    <div>
      <div className="contactcontainer">
        <div className="main">
          <h1 className="centered-text">Contact Us</h1>
        </div>
      </div>
      <div className='body'>
        <div>
          <div className="contact-centered-text">
            <h3>We Love To Hear From You</h3>
            <p>Please call or email contact form, and we will be happy to assist you.</p>
            <div>
              <form onSubmit={handleSubmit}>
                <div>
                  <div className='inputbody'>
                    <input
                      type="text"
                      name="FName"
                      placeholder="First Name:"
                      className='input'
                      value={formData.FName}
                      onChange={handleInputChange}
                    />
                    <input
                      type="text"
                      name="LName"
                      className='input'
                      placeholder="Last Name:"
                      value={formData.LName}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className='inputbody'>
                    <input
                      type="text"
                      name="mail"
                      className='input'
                      placeholder="Your mail:"
                      value={formData.mail}
                      onChange={handleInputChange}
                    />
                    <input
                      type="text"
                      name="phone"
                      className='input'
                      placeholder="Your Phone:"
                      value={formData.phone}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className='inputbody'>
                    <input
                      type="text"
                      name="company"
                      placeholder="Your Company:"
                      className='input'
                      value={formData.company}
                      onChange={handleInputChange}
                    />
                    <input
                      type="text"
                      name="subject"
                      className='input'
                      placeholder="Subject:"
                      value={formData.subject}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className='inputbody'>
                    <input
                      type="text"
                      name="message"
                      placeholder="Message:"
                      className='mailinput'
                      value={formData.message}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <button type="submit" className='button'>SEND MESSAGE</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div>
        <div style={{ padding: '5px' }} >
          <div className="map-iframe">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3939.843854467915!2d7.46135781525989!3d9.077985990718132!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104e0ae660361ab3%3A0x58bddd986ab0e865!2s1%20Nairobi%20St%2C%20Wuse%2C%20Abuja!5e0!3m2!1sen!2sng!4v1567518719528!5m2!1sen!2sng"
              title="Google Map"
              frameBorder="0"
              style={{ width: "100%", height: "300px", border: "0" }}
              allowFullScreen
            ></iframe>
          </div>
          <div className='descriptionbody'>
            <h4>Contact Deo Alpha Health Services Limited</h4>
            <div className='contactdescription'>
              <p>
                <strong>Address: </strong>1 Nairobi Road, WUSE 2 FCT Abuja, Nigeria
              </p>
              <div>
                <p>
                  <strong>Phone: </strong>08033458454
                </p>
                <p>
                  <strong>Email: </strong>info@dalphahealth.com
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    
      <Footer />
    </div>
  );
};

