import React from "react";
import NavBar from "../NavBar";
import { NavLink } from "react-router-dom";
import hands from '../../images/hands.png';
import Oladapo from '../../images/oladapo.png';
import Lere from '../../images/lere.png';
import Segun from '../../images/segun.png';
import Lekan from '../../images/lekan2.jpeg';
import "./About.css";
import Footer from "./Footer";
export const About = () => {
  return (
    <div>
      <div className="container">
     
      <h1 className="centered-text">About Us</h1>
      </div>
  <body>
   <div className="corevalues">
    <div className="corevaluestext">
      <h2><strong>Our Core Values C.A.R.E</strong>
      
      </h2>
      <p>At <strong>
      Deo Alpha Health Services Limited, 
        </strong>
        our promise is to go with you every step of the way with exceptional <strong>
        Commitment,  
        </strong> to execute our tasks for your utmost <strong>
         Accountability 
        </strong> while treating you, your vision and your team with absolute <strong>
        Respect
        </strong> so as to deliver products of world class <strong>
        Excellence
        </strong>
        </p>
        <p>
          <strong>
          Deo Alpha Health Services Limited, we care
          </strong>
        </p>
    </div>
    <div>
    <img src={hands} alt="" className="corevaluesimg"  />
    </div>
   </div>
    <div className="aboutteam">
              <NavLink
                exact
                to="/afolabi"
              
              >
            <div>
      <div>
      <img src={Oladapo} alt="" className="teammembers" /> 
      </div>
      <p>
      Prof. Oladapo Afolabi
      </p>
     </div>
              </NavLink>
   
              <NavLink
                exact
                to="/lere"
              
              >
              <div>
      <div>
      <img src={Lere} alt="" className="teammembers" /> 
      </div>
      <p>
      Prof. Lere Baale
      </p>
     </div>
              </NavLink>
              <NavLink
                exact
                to="/segun"
              
              >
              <div>
      <div>
      <img src={Segun} alt="" className="teammembers" /> 
      </div>
      <p>
      Dr. Segun Dogunro
      </p>
     </div>
              </NavLink>
              <NavLink
                exact
                to="/lekan"
              
              >
              <div>
      <div>
      <img src={Lekan} alt=""className="teammembers" style={{ maxWidth: "90%", height: "50%" }} /> 
      </div>
      <p>
      Prince Lekan Latona
      </p>
     </div>
              </NavLink>
     
   
    </div>
      <Footer />
    </body>
</div>

  );
};
