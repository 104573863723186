import React from "react";
import careerimg from '../../images/careerimg.jpg';
import NavBar from "../NavBar";
import lekan from '../../images/lekan.jpeg';
import Footer from "./Footer";

import "./Afolabi.css";
export const Lekan = () => {
  return (
    <div>
       <div className="Afolabicontainer">
  
    <div>
       
      <h1 className="centered-text">Deo Alpha Health Services Limited</h1>
      <p className="centered-text" style={{ fontSize: "20px" }}>World Class Health Company</p>
    </div>
    
  </div>
  <body>
  <div className="teambody">
    <p class="image-and-text">
        <img src={lekan} alt="" className="teamimage" />
        <span>
        
Lekan is an enthusiastic and committed professional who has a strong scientific and analytical background, excellent in planning and with good communication skills. Very early in his career he has been exposed to best practices in product sales & marketing, product and channel development, this has made him champion and birth a number of products across different job designations
        </span>
    <p>
      <span>
      A graduate of Industrial chemistry from Federal University of Technology, Akure and MBA degree holder from Business School Netherlands. He is also a Fellow of the Chartered Institute of Marketing UK.  his work experience cuts across manufacturing, banking, payment & card business, with exploits in the card and payment industry spanning about two decades.
      </span>
    </p>
      <p>
        <span>
        Lekan Latona is dynamic, savvy, highly creative and a skilled marketer with strong business and technical background which covers years customer service, market development and brand management. He is IT proficient and highly instinctive in identifying, nurturing and manifesting great business ideas. 
        </span>
        </p> 
        <p>
          <span>
          He is an excellent written and verbal communicator with listening ears for customer issues, proffering appropriate, fast and satisfactory solutions.
          </span>
        </p>
      
          
    </p>
</div>


    <Footer />
  </body>
    </div>
  );
};


