import "./App.css";
import NavBar from "./components/NavBar.js";
import { BrowserRouter as Router,  Route, Routes } from "react-router-dom";
import { Home } from "./components/Pages/Home";
import { About } from "./components/Pages/About";
import { Careers } from "./components/Pages/Careers";
import { Services } from "./components/Pages/Services";
import { Contact } from "./components/Pages/Contact";
import {Afolabi} from "./components/Pages/Afolabi";
import {Lere} from "./components/Pages/Lere";
import {Segun} from "./components/Pages/Segun";
import {Lekan} from "./components/Pages/Lekan";
function App() {
  return (
    <>
      <Router>
        <NavBar /> 

        <div className="pages">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/afolabi" element={<Afolabi />} />
            <Route path="/lere" element={<Lere />} />
            <Route path="/segun" element={<Segun />} />
            <Route path="/lekan" element={<Lekan />} />
          </Routes>
        </div>
      </Router>
  </>
  );
}

export default App;
