import React from "react";
import "./Footer.css";

function Footer(){
    return(
        <div>
        <div className="footer">
            <p className="text-right">
            Deo Alpha Health Services
            </p>
        </div>
    </div>
    )
}
export default Footer;

