import React, { useEffect, useState } from "react";
import img1 from '../../images/dalphahealthimg2.jpg';
import img2 from '../../images/dalphahealthimg4.jpg';
import cipla from '../../images/cipla.png';
import evee from '../../images/evee.png';
import { NavLink } from "react-router-dom";
import mb from '../../images/mb.png';
import mzor from '../../images/mzor.png';
import pfizer from '../../images/pfizer.png';
import sandoz from '../../images/sandoz.png';
import sdfefer from '../../images/sdfefer.jpg';
import address from '../../images/addressico.png';
import mail from '../../images/mailico.png';
import phone from '../../images/phoneico.png';
import instagram from '../../images/instagramico.png';
import twitter from '../../images/twitterico.png';
import sher from '../../images/sher.png';
import "./Home.css";
import NavBar from "../NavBar";
import Footer from "./Footer";
export const Home = () => {
  const [shouldAnimate, setShouldAnimate] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const textDiv = document.querySelector(".text-div");
      const secondtextDiv = document.querySelector(".secondtext-div");
      const centered_content = document.querySelector(".centered-content");
      if (textDiv) {
        const rect = textDiv.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        // Check if the top of the text div is within the viewport
        if (rect.top < windowHeight && rect.bottom >= 0) {
          setShouldAnimate(true);
        } else {
          setShouldAnimate(false);
        }
      } else if (centered_content) {
        const rect = centered_content.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        // Check if the top of the maindiv is within the viewport
        if (rect.top < windowHeight && rect.bottom >= 0) {
          setShouldAnimate(true);
        } else {
          setShouldAnimate(false);
        }
      }else if (secondtextDiv) {
        const rect = centered_content.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        // Check if the top of the maindiv is within the viewport
        if (rect.top < windowHeight && rect.bottom >= 0) {
          setShouldAnimate(true);
        } else {
          setShouldAnimate(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <div className="homecontainer">

      <div className="maindiv">
       
        <div className={`centered-content ${shouldAnimate ? "slide-up" : ""}`}>
          <div className="deo">
            Deo Alpha Health Services
          </div>
          <div>
            <div className="navigate">
              …navigating from idea to market, presenting the perfect product
            </div>
            <button className="discover-button">
              <NavLink
                exact
                to="/services"
              
              >
                DISCOVER MORE
              </NavLink>
            
            </button>
          </div>
        </div>
      </div>
      </div>
     
      <div className="seconddiv">
      <div className={`text-div ${shouldAnimate ? "slide-left" : ""}`}>
        <h1>Who We Are</h1>
        <p>Deo Alpha Health Service is a world-class Africa based research organization comprised of a team of experts with over 200 years combined experience in various fields relevant to the provision of quality scientific investigations for the bio-pharmaceutical and medical device industries. We are a consortium that nurtures ideas, midwife models and deliver excellence in bio-pharmaceutical and medical devices.</p>
        <p>We are a health research, analysis and solutions company committed to accountability and excellence.</p>
      </div>
      <div className={`image-div ${shouldAnimate ? "slide-up" : ""}`}>
        <img src={img1} alt="" className="seconddivimg" />
      </div>
      </div>
      <div className="thirddiv">
        <div className="second-centered-content">
        <h1>Company Statement</h1>
        <p>To take advantage of our experience, the exceptionality of our location and with the aid of technology grow the bio-pharmaceutical and medical device industries in Africa and the Middle East.</p>
        </div>
        
      </div>
      <div className="fourthdiv">
      <div className={`image-div ${shouldAnimate ? "slide-up" : ""}`}>
        <img src={img2} alt="" className="thirddivimg" />
      </div>
      <div className={`text-div-second ${shouldAnimate ? "slide-right" : ""}`}>
        <h1>What We Do</h1>
        <p style={{ marginBottom: '30px' }}>Deo Alpha Health Service provides exceptional, complete and appropriate services to complement, assist and advice clients in the pharma and medical device industries.  Using cutting edge technology and innovative solutions we provide evidence based results through carefully selected, well-designed, meticulously executed and professionally presented researches including clinical and non clinical trials, analyses, reviews and studies ultimately helping to grow industries.</p>
        
        <p>
We guide companies every step of the way from conception and nurturing to delivery of end-products as well as provide post-marketing information for both new and already existing products. This is to ensure that all aspects and processes involved are optimized, standardized and geared towards achieving competitive and rewarding products.</p>
    
      </div>
      
      </div>
      <div className="fifthdiv">
    
      <div className="automation">
        <h1 style={{fontSize: "18px" }}>Automation</h1>
        <p style={{ fontSize: "18px", fontFamily: "Rubix", lineHeight: "28px", fontWeight: "400" }}>Deo Alpha Health Services also engages in automation of processes and adaptation of technologies for improved efficiency, reduction of costs and maintenance of quality. We identify procedures, routines and actions that can be done better and suggest/advice better ways and technologies that can improve them.</p>
     
     </div>
     <div className="commercialization">
        <h1 style={{fontSize: "18px" }}>Commercialization</h1>
        <p style={{ fontSize: "18px", fontFamily: "Rubix", lineHeight: "28px", fontWeight: "400" }}>
        Commercialization of ideas and products at all levels of development - concepts, prototypes, finished products etc is also one of our strengths. We identify needs and markets for products and services and advice on the best methods to engage.</p>
     
     </div>
     <div className="automation">
        <h1 style={{fontSize: "18px" }}>Product Launch</h1>
        <p style={{ fontSize: "18px", fontFamily: "Rubix", lineHeight: "28px", fontWeight: "400" }}>We also engage in trainings and staff development for individuals and corporate bodies to enhance competencies and create a twenty first century work force that can handle emerging realities and technologies in an evolving environment.</p>
     
     </div>
     <div className="commercialization">
        <h1 style={{fontSize: "18px" }}>Scientific Test</h1>
        <p style={{ fontSize: "18px", fontFamily: "Rubix", lineHeight: "28px", fontWeight: "400" }}>Deo Alpha Health Service handles complex, one-off and stand-alone tests for corporate bodies and individuals including all sorts of assays and analyses.</p>
     
     </div>
      </div>
      <div className="sixthdiv">
        <div className="sixthdivtext">
          <h1>
          People we have worked with
          </h1>
          <p>Based on combined promoter’s experience</p>
        </div>
        <div className="sixthdivimage">
          <div className="imageborder">
          <img src={cipla} alt="" className="imagerowimage" />
          </div>
          <div className="imageborder">
          <img src={mzor} alt="" className="imagerowimage" />
          </div>
          <div className="imageborder">
          <img src={mb} alt="" className="imagerowimage" />
          </div>
          <div className="imageborder">
          <img src={evee} alt="" className="imagerowimage" />
          </div>
          
          <div className="imageborder">
          <img src={pfizer} alt="" className="imagerowimage" />
          </div>
          <div className="imageborder">
          <img src={sandoz} alt="" className="imagerowimage" />
          </div>
          <div className="imageborder">
          <img src={sher} alt="" className="imagerowimage" />
          </div>
          <div className="imageborder">
          <img src={sdfefer} alt="" className="imagerowimage" />
          </div>
        </div>
    
      </div>
      <div className="seventhdiv">
  <div className="map-container">
    <div className="map-iframe">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3939.843854467915!2d7.46135781525989!3d9.077985990718132!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104e0ae660361ab3%3A0x58bddd986ab0e865!2s1%20Nairobi%20St%2C%20Wuse%2C%20Abuja!5e0!3m2!1sen!2sng!4v1567518719528!5m2!1sen!2sng"
        title="Google Map"
        frameBorder="0"
        style={{ width: "100%", height: "346px", border: "0" }}
        allowFullScreen
      ></iframe>
    </div>
    <div className="map-description">
      <p>GET IN TOUCH</p>
      <h2>Need Help? Contact Us.</h2>
      <div style={{ display: 'flex'}}>
      <img src={address} alt="" style={{ width: '6%', height: '6%' }}/>
      <p style={{ fontSize: '18px', marginTop: '4px' }}>1 Nairobi Road, WUSE 2 FCT Abuja, Nigeria</p>
      </div>
      <div style={{ display: 'flex'}}>
      <img src={mail} alt="" style={{ width: '6%', height: '6%'}}/>
      <p style={{ fontSize: '18px', marginTop: '4px' }}>info@dalphahealth.com</p>
      </div>
      <div style={{ display: 'flex'}}>
      <img src={phone} alt="" style={{ width: '6%', height: '6%' }} />
      <p style={{ fontSize: '18px', marginTop: '4px' }}>+234 803 345 8454</p>
      </div>
      <div style={{ display: 'flex'}}>
      <img src={instagram} alt="" style={{ width: '6%', height: '6%' }}/>
      <p style={{ fontSize: '18px', marginTop: '4px' }}>@dalphahealth</p>
      </div>
      <div style={{ display: 'flex'}}>
      <img src={twitter} alt="" style={{ width: '6%', height: '6%' }}/>
      <p style={{ fontSize: '18px', marginTop: '4px' }}>@dalphahealth</p>
      </div>
      <p style={{ fontSize: '18px', marginTop: '4px' }}>Give us a call or drop by anytime, we endeavor to answer all enquirers within 24 hours on business days.</p>
    </div>
  </div>


        <div>

        </div>
        </div>
        <Footer />
    </div>
  );
};
